<template>
  <v-container fluid>
    <v-sheet :width="$vuetify.breakpoint.mobile ? '92vw' : '80vw'" class="mx-auto">
      <v-container fluid class="navbar d-flex flex-row">
        <v-row class="align-center" no-gutters>
          <v-col cols="auto">
            <div class="text-h6 font-weight-regular">Statistik</div>
          </v-col>
          <v-col></v-col>
          <v-col cols="auto">
            <r-btn @click="downloadCsv" action>Download CSV fil</r-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table :items="stats" :headers="headers" v-bind="$util.dataTableOptions()" disable-pagination hide-default-footer>
        <template #[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <r-btn icon @click.stop="organizationDetails(item)" title="Detaljer">
              <v-icon>mdi-dots-horizontal</v-icon>
            </r-btn>
          </div>
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import _ from 'lodash';
import apiService from '@/services/apiService';
import { mapGetters } from 'vuex';

export default {
  name: 'admin-statistics',
  data: () => ({
    stats: [],
    headers: [
      {
        text: 'Navn',
        value: 'name',
      },
      {
        text: 'CVR',
        value: 'cvr',
      },
      {
        text: 'Tlf. Nummer',
        value: 'phone',
      },
      {
        text: 'Elever',
        value: 'studentsCount',
      },
      {
        text: 'Lektionsplaner',
        value: 'moduleListsCount',
      },
      {
        text: 'Moduler',
        value: 'modulesCount',
      },
      {
        text: '',
        value: 'actions',
      },
    ],
  }),
  methods: {
    async getStats() {
      this.stats = await apiService.getOrganizationReport();
    },
    organizationDetails(org) {
      this.$router.push({ name: 'orgDetails', params: { OrgId: org.id } });
    },
    downloadCsv() {
      let rows = [];

      const items = this.headers.filter(x => x.text);

      // top row - headers
      rows.push(items.map(x => x.text));

      // data rows including total
      rows = _.concat(
        rows,
        this.stats.map(stat => items.map(i => stat[i.value])),
      );

      const csvContent = `data:text/csv;charset=utf-8,\ufeff${rows
        .map(e =>
          e
            .map(item => {
              // escape items that contain comma
              if (item === null) {
                return '';
              }

              return item.toString().indexOf(';') !== -1 ? `"${item}"` : item;
            })
            .join(';'),
        )
        .join('\n')}`;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `Koreskoleguide Statistik ${DateTime.local().toFormat('yyyy-MM-dd')}.csv`);
      document.body.appendChild(link);

      link.click();
    },
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
  created() {
    this.getStats();
  },
};
</script>